import { Link, useNavigate } from "react-router-dom";
const Header = () => {
    const Navigate = useNavigate();
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        return Navigate(`/result/${searchValue}`);
    }

    return (
        <header className="shadow-sm">
            <div className="topbar topbar-dark bg-dark mb-2">
                <div className="container">
                    <div className="topbar-text dropdown d-md-none">
                        <a className="topbar-link dropdown-toggle" href="#0" data-bs-toggle="dropdown">Useful links</a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href="tel:00331697720"><i className="ci-support text-muted me-2" />(00) 33 169 7720</a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="/"><i className="ci-location text-muted me-2" />Order tracking</a>
                            </li>
                        </ul>
                    </div>
                    <div className="topbar-text text-nowrap d-none d-md-inline-block"><i className="ci-support" /><span className="text-muted me-1">Support</span><a className="topbar-link" href="tel:00331697720">(00) 33 169 7720</a></div>
                    <div className="tns-carousel tns-controls-static d-none d-md-block">
                        <div className="tns-carousel-inner" data-carousel-options="{&quot;mode&quot;: &quot;gallery&quot;, &quot;nav&quot;: false}">
                            <div className="topbar-text">Free shipping for order over $200</div>
                            <div className="topbar-text">We return money within 30 days</div>
                            <div className="topbar-text">Friendly 24/7 customer support</div>
                        </div>
                    </div>
                    <div className="ms-3 text-nowrap">
                        <a className="topbar-link me-4 d-none d-md-inline-block" href="/"><i className="ci-location" />Order tracking</a>
                        <div className="topbar-text dropdown disable-autohide">
                            <a className="topbar-link dropdown-toggle" href="/" data-bs-toggle="dropdown">English</a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a className="dropdown-item pb-1" href="/">Arabic</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-sticky bg-light">
                <div className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link className="navbar-brand d-none d-sm-block flex-shrink-0" to="/">
                            <span className="text-dark">Doski</span><span className="text-primary">Trade</span>
                        </Link>
                        <Link className="navbar-brand d-sm-none flex-shrink-0 me-2" to="/">
                            <span className="text-dark">Doski</span><span className="text-primary">Trade</span>
                        </Link>
                        <div className="input-group d-none d-lg-flex mx-4">
                            <input className="form-control rounded-end pe-5" type="text" placeholder="Search for products" name="search" onKeyDown={(e) => { e.key === 'Enter' && handleSearch(e); }} />
                            <i className="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3" />
                        </div>
                        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/shop">Shop</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/new">New</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/discount">Discount</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
}

export default Header;